.Contact {
  display: flex;
  flex-direction: column;
  flex: 2;

  .ContactDetails {
    h1, h2 {
      font-size: 32px;
    }
  }

  &.dark-mode {
    .ContactDetails {
      color: var(--color-white);
    }
  }

  .ContactWrap {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto min-content;
    column-gap: var(--padding-large);

    .contactMethods {
      flex: 1;
      justify-content: space-around;
      max-width: 300px;
      max-height: 480px;
      padding-top: var(--padding-small);
      .contactMethodsDirect {
        display: flex;
        flex-direction: column;
        .contactMethodItem {
          display: flex;
          align-items: center;
          padding-bottom: 28px;
          .contact-method-icon {
            margin-right: calc(var(--padding-small) * 1.8);
          }
          p,
          h3 {
            margin: 0;
            font-size: 16px;
          }
        }
      }
      .socialIcons {
        display: flex;
        margin: auto;
        padding-top: 20px;
        a {
          margin-right: var(--padding-regular);
        }
      }
    }
  }
}

@media (max-width: 820px) {}


@media (max-width: 576px) {
  .Contact {
    .ContactWrap {
      grid-auto-flow: row;
      grid-template-columns: auto;

      .contactMethods {
        margin-top: var(--padding-regular);
        max-width: 100%;
      }
    }
  }
}